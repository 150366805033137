import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../redux/store';

const getBaseURL = (env: any) => {
  if (env === 'development') return process.env.REACT_APP_BASE_URL_DEV
  else return process.env.REACT_APP_BASE_URL
}

const BASE_URL = getBaseURL(process.env.NODE_ENV);

export interface SignedURLRequest {
  fileName: any,
  mimeType: any,
  folderName: string
}

export interface UploadFileRequest {
  url: string,
  file: any
}

// initialize an empty api service that we'll inject endpoints into later as needed
export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers: any, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['StoreProperty', 'Reports', 'Cases', 'Users', 'Practices', 'Bills', 'Payments', 'Discounts', 'SKUS', 'Modifications', 'Proposals'],
  endpoints: (builder) => ({
    getSignedUrl: builder.mutation<any, SignedURLRequest>({
      query: (credentials) => ({
        url: `/signed-url`,
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
});
export const { useGetSignedUrlMutation } = api; 